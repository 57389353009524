
import Vue, { Ref, computed, getCurrentInstance, ref } from "vue";
import { ChapterMenuItem } from "@/types";
import { mdiBookmarkMultiple } from "@mdi/js";
export default Vue.extend({
  name: "HandbookMenu",
  setup() {
    const proxy = getCurrentInstance()?.proxy;
    const active: Ref<ChapterMenuItem[]> = ref([]);
    const menu = computed(() => proxy?.$store.getters["handbook/menu"]);
    const navigate = ({ title, chapterId, type }: ChapterMenuItem): void => {
      proxy?.$router.push({
        name: "Chapter",
        params: { id: chapterId },
        query: {
          title: encodeURIComponent(title),
          type: type as string
        }
      });
    };
    const onChapterSelect = (val: ChapterMenuItem[]): void => {
      navigate(val[0]);
    };
    const stripAllTags = (title: string): string => {
      return title.replace(/<(.|\n)*?>/g, "");
    };
    return {
      mdiBookmarkMultiple,
      active,
      menu,
      onChapterSelect,
      stripAllTags
    };
  }
});
