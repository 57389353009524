
import Vue, { computed, getCurrentInstance, ref } from "vue";
import { mdiInformationOutline, mdiChevronRight } from "@mdi/js";
import InstallGuideDialog from "@/components/InstallGuideDialog.vue";
export default Vue.extend({
  name: "InstallAlert",
  components: {
    InstallGuideDialog
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy;
    const showInstallGuide = ref(false);
    const showAlert = computed(() => {
      return proxy?.$store.state.settings.installAlert;
    });
    const setInstallAlert = (state: boolean): void => {
      proxy?.$store.dispatch("settings/setInstallAlert", state);
    };
    return {
      showInstallGuide,
      showAlert,
      setInstallAlert,
      mdiInformationOutline,
      mdiChevronRight
    };
  }
});
