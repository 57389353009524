
import Vue, { toRefs } from "vue";
import { mdiChevronRight } from "@mdi/js";

export default Vue.extend({
  name: "Shortcut",
  setup(props) {
    const { icon, to, text } = toRefs(props);
    return {
      icon,
      to,
      text,
      mdiChevronRight
    };
  },
  props: {
    icon: String,
    to: [String, Object],
    text: String
  }
});
