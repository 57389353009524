import Vue from "vue";
import Vuex from "vuex";
import handbook from "./modules/handbook";
import projects from "./modules/projects";
import alert from "./modules/alert";
import auth from "./modules/auth";
import settings from "./modules/settings";
import article from "./modules/article";
import information from "./modules/information";
import VuexPersistence, { AsyncStorage } from "vuex-persist";
import { RootState } from "./types";
import localForage from "localforage";

Vue.use(Vuex);

const vuexForage = new VuexPersistence<RootState>({
  storage: localForage as AsyncStorage,
  asyncStorage: true,
  modules: ["handbook", "projects", "article", "information"]
});

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ["auth", "settings"]
});

const store = new Vuex.Store({
  modules: {
    handbook,
    projects,
    article,
    alert,
    settings,
    auth,
    information
  },
  plugins: [vuexForage.plugin, vuexLocal.plugin]
});

export default store;
export const useStore = () => store;
