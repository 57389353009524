export interface AlertState {
  alerts: Array<Alert>;
}

export interface Alert {
  id?: number;
  text: string;
  type?: AlertType;
  dismissAfter?: number;
  action?: Function;
  actionText?: string;
  identifier?: string;
  dismissible?: boolean;
}

export enum AlertType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success"
}
