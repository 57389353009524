
import Vue, { PropType, Ref, ref, toRefs, watch } from "vue";
import { mdiClose } from "@mdi/js";
import { VuetifyForm } from "@/types";
import { VALIDATION } from "@/utils";
import { User } from "victoria-shared";
import { useStore } from "@/store";
export default Vue.extend({
  name: "EditProfileForm",
  props: {
    user: Object as PropType<User>
  },
  setup(props, context) {
    const store = useStore();
    const { user } = toRefs(props);
    const loading = ref(false);
    const valid = ref(false);
    const email: Ref<string | undefined> = ref(undefined);
    const phoneNumber: Ref<string | undefined> = ref(undefined);
    const rules = {
      ...VALIDATION.required(),
      ...VALIDATION.email()
    };
    const form: Ref<VuetifyForm | undefined> = ref(undefined);
    const getForm = (): VuetifyForm | undefined => {
      return form.value;
    };
    const validate = (): boolean | undefined => {
      return getForm()?.validate();
    };
    const submit = async (): Promise<void> => {
      if (validate()) {
        loading.value = true;
        const result = await store.dispatch("auth/updateAccount", {
          email: email.value,
          phoneNumber: phoneNumber.value
        });
        if (result) {
          context.emit("change");
        }
      }
      loading.value = false;
    };
    watch(
      () => user,
      newVal => {
        email.value = newVal.value.email;
        phoneNumber.value = newVal.value.phoneNumber;
      },
      { immediate: true }
    );
    return {
      mdiClose,
      form,
      valid,
      rules,
      submit,
      phoneNumber,
      email,
      loading
    };
  }
});
