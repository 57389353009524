
import Vue, { computed, getCurrentInstance } from "vue";
export default Vue.extend({
  name: "Alert",
  setup() {
    const proxy = getCurrentInstance()?.proxy;
    const alerts = computed(() => proxy?.$store.state.alert.alerts);
    const close = (id: number): void => {
      proxy?.$store.dispatch("alert/remove", id);
    };
    const performAction = (id: number, action: Function): void => {
      action();
      close(id);
    };
    return {
      performAction,
      close,
      alerts
    };
  }
});
