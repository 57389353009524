
import Vue from "vue";
import { mdiHome, mdiBookOpenVariant, mdiFolderTextOutline, mdiAccountGroup } from "@mdi/js";

export default Vue.extend({
  setup() {
    return {
      mdiHome,
      mdiBookOpenVariant,
      mdiFolderTextOutline,
      mdiAccountGroup
    };
  }
});
