import store from "@/store";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";

// Time to consider for refresh of token prior to token expiration
const TTL_MARGIN = 1000 * 60 * 5;

let refreshTokenPromise: Promise<void> | undefined = undefined;

export default async (instance: AxiosInstance, withAuth = true) => {
  if (withAuth) {
    instance.interceptors.request.use(async config => {
      const isAuthenticated = store.getters["auth/isAuthenticated"];
      if (isAuthenticated) {
        const expiry = store.getters["auth/getTokenExpiry"];
        if (!expiry || Date.now() + TTL_MARGIN >= expiry) {
          if (!refreshTokenPromise) {
            console.info("Refreshing token...");
            refreshTokenPromise = store.dispatch("auth/refreshToken");
            await refreshTokenPromise;
            refreshTokenPromise = undefined;
          } else {
            console.info("Waiting for token refresh...");
            await refreshTokenPromise;
          }
        }
        const { token } = store.state.auth;
        if (token) {
          config.headers.common["Authorization"] = `Bearer ${token}`;
        }
      }
      return config;
    });
  }

  instance.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const isAuthenticated = store.getters["auth/isAuthenticated"];
      if (isAuthenticated && error.response && [401, 403].includes(error.response.status)) {
        console.warn("Unauthorized, logging out user...");
        if (!error.response?.config.url?.includes("revoke-token")) {
          await store.dispatch("auth/logout");
        }
      }
      if (error.response && error.response.status === 500) {
        error.response.data.message = "Something went wrong...";
      }
      throw error;
    }
  );
};
