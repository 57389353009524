
import Vue, { computed, getCurrentInstance, ref } from "vue";
import { mdiAccount, mdiChevronLeft } from "@mdi/js";

export default Vue.extend({
  name: "TheAppBar",
  setup() {
    const proxy = getCurrentInstance()?.proxy;
    const mode = ref(process.env.VUE_APP_MODE);
    const showBackButton = computed(() => {
      const nested = proxy ? proxy?.$route.path.split("/").length - 1 > 1 : -1;
      return nested;
    });
    const goBack = (): void => {
      proxy?.$router.go(-1);
    };
    return {
      mode,
      mdiAccount,
      mdiChevronLeft,
      showBackButton,
      goBack
    };
  }
});
