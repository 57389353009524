
import Vue, { computed, getCurrentInstance } from "vue";
import TheAppBar from "@/components/TheAppBar.vue";
import TheBottomBar from "@/components/TheBottomBar.vue";
import Alert from "@/components/Alert.vue";
import AppUpdateAlert from "@/components/AppUpdateAlert.vue";
import { useStore } from "./store";
const GUARDED_ROUTES = ["Login", "Register", "RegisterSuccess", "Reset"];

export default Vue.extend({
  name: "App",
  components: { TheAppBar, TheBottomBar, Alert, AppUpdateAlert },
  setup() {
    const store = useStore();
    const proxy = getCurrentInstance()?.proxy;
    const showNavigation = computed(
      () => proxy?.$route.name && !GUARDED_ROUTES.includes(proxy?.$route.name)
    );
    const restoreState = async (): Promise<void> => {
      if (store.getters["auth/isAuthenticated"]) {
        // @ts-expect-error Wait for handbook state to be restored,
        // restored is added to the store by vuex-persist and resolves when state is restored.
        await store.state.restored;
        store.dispatch("article/initialize");
        store.dispatch("handbook/initialize");
        store.dispatch("projects/initialize");
        store.dispatch("information/initialize");
      }
    };
    restoreState();
    return {
      showNavigation
    };
  }
});
