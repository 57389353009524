import { Article } from "victoria-shared";
import request from "../request";

const CONTROLLER = "informationArticles";

export default {
  async GetInformationArticles(offset = 0): Promise<Array<Article>> {
    const req = await request.get(`${CONTROLLER}`, {
      params: { offset }
    });
    return req.status === 200 ? req.data : [];
  },
  async GetInformationArticle(id: string): Promise<Article> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
