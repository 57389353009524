import axios from "axios";
import intercept from "./interceptor";

const requestConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
};

export const authlessRequest = axios.create(requestConfig);
const request = axios.create(requestConfig);

intercept(authlessRequest, false);
intercept(request);

export default request;
