import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { Alert, AlertState, AlertType } from "./types";
import * as mutation from "./mutations";

const DISMISS_INTERVAL = 5000;

const DEFAULT_TYPE = AlertType.INFO;

let currentToastId = 0;

function initialState(): AlertState {
  return {
    alerts: []
  };
}

const getters: GetterTree<AlertState, RootState> = {
  alerts: state => state.alerts
};

const actions: ActionTree<AlertState, RootState> = {
  add(
    { commit },
    {
      text,
      action,
      actionText,
      type = DEFAULT_TYPE,
      dismissible = true,
      dismissAfter = DISMISS_INTERVAL
    }
  ) {
    const id = ++currentToastId;
    commit(mutation.ADD, { id, text, type, dismissAfter, action, actionText, dismissible });
    setTimeout(() => commit(mutation.REMOVE, id), dismissAfter);
  },
  addPersistent(
    { commit, state },
    { text, action, actionText, identifier, type = DEFAULT_TYPE, dismissible = false }
  ) {
    const id = ++currentToastId;
    // Make sure we don't add the same persistent notification more than once
    if (state.alerts.every(a => a.identifier !== identifier)) {
      commit(mutation.ADD, { id, text, type, action, actionText, identifier, dismissible });
    }
  },
  remove({ commit }, id) {
    commit(mutation.REMOVE, id);
  },
  removePersistent({ commit }, identifier) {
    commit(mutation.REMOVE_BY_IDENTIFIER, identifier);
  }
};

const mutations: MutationTree<AlertState> = {
  [mutation.ADD](state, data: Alert) {
    state.alerts.push(data);
  },
  [mutation.REMOVE](state, id: number) {
    state.alerts = state.alerts.filter(a => a.id !== id);
  },
  [mutation.REMOVE_BY_IDENTIFIER](state, identifier: string) {
    state.alerts = state.alerts.filter(a => a.identifier !== identifier);
  }
};

const module: Module<AlertState, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};

export default module;
