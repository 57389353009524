
import Vue, { getCurrentInstance, ref } from "vue";
import { AlertType } from "@/store/modules/alert/types";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "LoadMoreArticlesButton",
  setup() {
    const proxy = getCurrentInstance()?.proxy;
    const loading = ref(false);
    const fetch = async (): Promise<void> => {
      loading.value = true;
      try {
        const newArticles = await proxy?.$store.dispatch("article/fetchMore");
        if (!newArticles.length) {
          proxy?.$store.dispatch("alert/add", {
            text: "There are no more news articles.",
            type: AlertType.INFO
          });
        }
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message =
          errorResponse?.response?.data?.message || "Failed getting more news articles.";
        proxy?.$store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    return {
      loading,
      fetch
    };
  }
});
