
import Vue from "vue";
import { mdiEmoticonSadOutline } from "@mdi/js";

export default Vue.extend({
  name: "NotFound",
  setup() {
    return { mdiEmoticonSadOutline };
  }
});
