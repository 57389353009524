
import Vue, { ref, computed } from "vue";
import InformationArticleList from "@/components/InformationArticleList.vue";
import { Article } from "victoria-shared";
import { AlertType } from "@/store/modules/alert/types";
import { AxiosError } from "axios";
import { useStore } from "@/store";
export default Vue.extend({
  name: "InformationSection",
  components: { InformationArticleList },
  setup() {
    const store = useStore();
    const stateRestored = ref(false);
    const isStateRestored = async () => {
      // @ts-expect-error Wait for handbook state to be restored,
      stateRestored.value = !!((await store.state.restored) || true);
    };
    const articles = computed((): Article[] => {
      return store.state.information.articles || [];
    });
    const refreshing = computed((): boolean => {
      return store.state.information.fetching;
    });
    const fetchArticles = async (): Promise<void> => {
      try {
        await store.dispatch("information/fetchAll");
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Could not fetch news articles.";
        store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
      }
    };
    isStateRestored();
    return {
      stateRestored,
      articles,
      refreshing,
      fetchArticles
    };
  }
});
