
import Vue, { PropType, computed, toRefs } from "vue";
import { Article } from "victoria-shared";
import InformationArticleCard from "@/components/InformationArticleCard.vue";

export default Vue.extend({
  name: "InformationArticleList",
  components: { InformationArticleCard },
  props: {
    articles: {
      type: Array as PropType<Article[]>,
      default: () => []
    }
  },
  setup(props) {
    const { articles } = toRefs(props);
    const allArticles = computed((): Article[] => {
      return articles.value;
    });
    return {
      articles,
      allArticles
    };
  }
});
