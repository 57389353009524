import { LoginDetails, RegisterRequest, ResetRequest } from "@/types";
import { LoginResponse, RefreshResponse, User } from "victoria-shared";
import request, { authlessRequest } from "../request";

const CONTROLLER = "accounts";

export default {
  async login(loginDetails: LoginDetails): Promise<LoginResponse> {
    const req = await authlessRequest.post(`${CONTROLLER}/authenticate`, loginDetails);
    return req.data;
  },
  async refreshToken(): Promise<RefreshResponse | undefined> {
    const req = await authlessRequest.post(`${CONTROLLER}/refresh-token`);
    return req.status === 200 ? req.data : undefined;
  },
  async logout(): Promise<boolean> {
    const req = await request.post(`${CONTROLLER}/revoke-token`, { validateStatus: () => true });
    return req.status === 200;
  },
  async register(data: RegisterRequest): Promise<boolean> {
    const req = await authlessRequest.post(`${CONTROLLER}/register`, {
      ...data
    });
    return req.status === 200;
  },
  async forgotPassword(email: string): Promise<{ message: string }> {
    const req = await authlessRequest.post(`${CONTROLLER}/forgot-password`, {
      email
    });
    return req.data;
  },
  async resetPassword(data: ResetRequest): Promise<{ message: string }> {
    const req = await authlessRequest.post(`${CONTROLLER}/reset-password`, {
      ...data
    });
    return req.data;
  },
  async deleteAccount(id: string): Promise<{ message: string }> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async updateAccount(id: string, { email, phoneNumber }: Partial<User>): Promise<User> {
    const req = await request.put(`${CONTROLLER}/${id}`, {
      email,
      phoneNumber
    });
    return req.data;
  }
};
