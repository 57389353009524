
import Vue, { Ref, computed, getCurrentInstance, ref } from "vue";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { VuetifyForm } from "@/types";
import { AlertType } from "@/store/modules/alert/types";
import api from "@/api";
import { VALIDATION } from "@/utils";
import { AxiosError } from "axios";
import { useStore } from "@/store";
export default Vue.extend({
  name: "ResetPasswordForm",
  setup() {
    const store = useStore();
    const proxy = getCurrentInstance()?.proxy;
    const loading = ref(false);
    const valid = ref(false);
    const formInput: Ref<VuetifyForm | undefined> = ref(undefined);
    let token: string | undefined = undefined;
    const form = ref({
      password: "",
      confirmPassword: ""
    });
    const showPassword = ref(false);
    const rules = {
      ...VALIDATION.required(),
      ...VALIDATION.min(8),
      ...VALIDATION.password()
    };
    const passwordMatch = computed(() => {
      return () => form.value.password === form.value.confirmPassword || "Password must match.";
    });
    const getForm = (): VuetifyForm | undefined => {
      return formInput.value;
    };
    const validate = (): boolean | undefined => {
      return getForm()?.validate();
    };
    const submit = async (): Promise<void> => {
      loading.value = true;
      if (validate()) {
        try {
          const { message } = await api.resetPassword({ token, ...form.value });
          proxy?.$router.replace({ name: "Login" });
          store.dispatch("alert/add", { text: message, type: AlertType.SUCCESS });
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message = errorResponse?.response?.data?.message || "Failed resetting password";
          store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
        }
      }
      loading.value = false;
    };
    token = proxy?.$route.params.token;
    return {
      loading,
      formInput,
      valid,
      showPassword,
      rules,
      passwordMatch,
      submit,
      mdiEye,
      mdiEyeOff
    };
  }
});
