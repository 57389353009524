import handbook from "./controllers/handbook";
import auth from "./controllers/auth";
import invites from "./controllers/invites";
import projects from "./controllers/projects";
import articles from "./controllers/articles";
import informationArticles from "./controllers/informationArticles";

export default {
  ...handbook,
  ...auth,
  ...invites,
  ...projects,
  ...articles,
  ...informationArticles
};
