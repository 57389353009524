import { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Handbook from "../views/Handbook.vue";
import Chapter from "../views/Chapter.vue";
import Projects from "../views/Projects.vue";
import PhoneDirectory from "../views/PhoneDirectory.vue";
import Article from "../views/Article.vue";
import Information from "../views/Information.vue";
import InformationArticle from "../views/InformationArticle.vue";
import NotFound from "../views/NotFound.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import Register from "../views/Register.vue";
import RegisterSuccess from "../views/RegisterSuccess.vue";
import Reset from "../views/Reset.vue";
import { loginGuard, registerGuard } from "./guards";

export const UNGUARDED_ROUTES = ["Login", "Register", "RegisterSuccess", "Reset"];

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/handbook",
    name: "Handbook",
    component: Handbook
  },
  {
    path: "/handbook/:id",
    name: "Chapter",
    component: Chapter
  },
  {
    path: "/article/:id",
    name: "Article",
    component: Article
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/crew-information",
    name: "Information",
    component: Information
  },
  {
    path: "/crew-information/:id",
    name: "InformationArticle",
    component: InformationArticle
  },
  {
    path: "/phone",
    name: "PhoneDirectory",
    component: PhoneDirectory
  },
  {
    path: "/login",
    name: "Login",
    component: SignIn,
    beforeEnter: loginGuard
  },
  {
    path: "/register/success",
    name: "RegisterSuccess",
    component: RegisterSuccess
  },
  {
    path: "/register/:token",
    name: "Register",
    component: Register,
    beforeEnter: registerGuard
  },
  {
    path: "/reset/:token",
    name: "Reset",
    component: Reset
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  { path: "/*", redirect: "/404" }
];
