
import Vue, {
  Ref,
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeMount,
  onMounted,
  ref
} from "vue";
import { mdiArrowLeft, mdiArrowRight, mdiBookOpenPageVariant } from "@mdi/js";
import { Dictionary } from "vue-router/types/router";
import { Chapter, ChapterMenuItem } from "@/types";
import { useStore } from "@/store";
export default Vue.extend({
  name: "Chapter",
  setup() {
    const store = useStore();
    const proxy = getCurrentInstance()?.proxy;
    const content: Ref<Element | undefined> = ref(undefined);
    const menu = computed(() => store.getters["handbook/menu"] as ChapterMenuItem[]);
    const id = computed((): string | undefined => proxy?.$route.params.id);
    const chapter = computed((): Chapter | undefined => {
      return store.state.handbook.chapters.find(({ chapterId }) => chapterId == id.value);
    });
    // const chapterRestored = async (): Promise<void> => {
    //   // @ts-expect-error Wait for handbook state to be restored,
    //   await store.state.restored;
    //   if (!chapter.value) {
    //     proxy?.$router.replace({ name: "404" });
    //   }
    // };
    const prevChapter = computed(
      (): ChapterMenuItem => {
        const chapters = menu.value;
        const index = chapters.findIndex(item => item.chapterId === id.value);
        return chapters[index - 1];
      }
    );
    const nextChapter = computed(
      (): ChapterMenuItem => {
        const chapters = menu.value;
        const index = chapters.findIndex(item => item.chapterId === id.value);
        return chapters[index + 1];
      }
    );
    const navigate = (chapterId: string): void => {
      proxy?.$router.replace({
        name: "Chapter",
        params: { id: chapterId }
      });
    };
    onMounted(() => {
      try {
        const { title, type } = proxy?.$route.query as Dictionary<string>;
        const decodedTitle = decodeURIComponent(title);
        if (title && type) {
          const doc = content.value;
          const headings = doc && (Array.from(doc.querySelectorAll(type)) as HTMLElement[]);
          const element = headings?.find(
            el => el.innerHTML === decodedTitle || el.innerText === decodedTitle
          );
          if (element) {
            nextTick(() => {
              setTimeout(() => {
                // element && element.scrollIntoView();
                const rect = element.getBoundingClientRect();
                window.scrollTo({
                  top: rect.top + window.scrollY - 80,
                  behavior: "smooth"
                });
              }, 50);
            });
          }
        }
      } catch {
        console.error("Failed scrolling to content");
      }
    });

    onBeforeMount(async () => {
      // @ts-expect-error Wait for handbook state to be restored,
      await store.restored;
      if (!chapter.value) {
        proxy?.$router.replace({ name: "404" });
      }
    });
    //chapterRestored();
    return {
      chapter,
      prevChapter,
      nextChapter,
      navigate,
      mdiArrowLeft,
      mdiArrowRight,
      mdiBookOpenPageVariant
    };
  }
});
