export const TERMS = `
<p><strong>By registering to this website you agree with the Notice of personal data processing.</strong></p>
<h2>Our principles</h2>
<p>According to Article 14 of the EU General Data Protection Regulation (GDPR) we must provide you
with various information when we process personal data related to you.</p>
<p>This Notice explains how we process (e.g. collect, use, store, and share) your personal data. We will
process any personal data about you in accordance with this Notice and with applicable law.</p>
<p>We recommend that you read this Notice carefully and contact us if you have any questions or
concerns regarding personal data.</p>
<h2>Who are we?</h2>
<p>NKT is the Data Collector responsible for processing of your personal data. You will find our contact
details below.</p>
<p>NKT HV Cables AB, PO Box 546, SE-371 23 Karlskrona, Sweden</p>
<p>You can always contact NKT at <a href="mailto:compliance@nkt.com">compliance@nkt.com</a> with questions or concerns about how we process your personal data.</p>
<h2>How do we collect personal data about you?</h2>
<p>We collect your personal data from the following sources:</p>
<p><ul><li>When signing up to use the NKT Victoria mobile application</li></ul></p>
<h2>Purpose and legal basis for processing of your personal data</h2>
<p>We process your personal data for the following purposes:</p>
<p><ul><li>To provide you with information relevant to NKT Victoria when using the mobile application</li></ul></p>
<h2>What personal data do we process about you?</h2>
<p>We process the following categories of your personal data:</p>
<p><ul><li>General contact information (including name, email, phone number, your employer)</li></ul></p>
<h2>How do we share your personal data?</h2>
<p>We do not share your personal data with anyone outside of NKT or with unaffiliated third persons, except for legitimate grounds or necessary to carry out your requests. Your personal data is transferred to the following recipients: </p>
<p><ul><li>Suppliers or vendors that assist our company (IT service providers that runs our IT systems, IT service provider that develops and manages our NKT Victoria Mobile Application);</li></ul></p>
<h2>Storage of your personal data</h2>
<p>We store your personal data for as long as needed to in order to be able to fulfil the purposes for which we collect it, or as long as required by applicable law.]</p>
<p>NKT ensures that data is kept and processed in a strictly confidential manner. NKT has implemented appropriate technical and organizational measures to ensure personal data security. </p>
<h2>Your rights</h2>
<p>According to the GDPR you have certain rights in relation to our processing of your personal data.</p>
<p>If you wish to exercise your rights, please contact us at <a href="mailto:compliance@nkt.com">compliance@nkt.com</a></p>
<h3>Right of access</h3>
<p>You have the right to obtain access to the personal data we process about you as well as various other information.</p>
<h3>Right to rectification</h3>
<p>You have the right to obtain rectification of inaccurate personal data related to you.</p>
<h3>Right to deletion</h3>
<p>In some cases, you have the right to have your personal data deleted prior to the time for our usual deletion.</p>
<h3>Right to restriction of processing</h3>
<p>In some cases, you have the right to restrict the processing of your personal data. If you have the right to restrict the processing, we may only process the personal data – except from storage – with your consent or in order to determine, exert or defend legal claims, or to protect an individual or vital public interests.</p>
<h3>Right to objection</h3>
<p>In some cases, you have the right to object to our or other legal processing of your personal data. You can object to processing of your personal data for direct marketing purposes.</p>
<h3>Right to data portability</h3>
<p>In some cases, you have the right to receive your personal data in a structure, commonly used and machine-readable format and have the right to transfer the personal data from one Data Collector to another without hindrance.</p>
<h2>Lodge a complaint with the Swedish Data Protection Authority</h2>
<p>You have the right to lodge a complaint with the Swedish Authority for Privacy Protection if you are dissatisfied with the way we process your personal data:
Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm.</p>
`;
