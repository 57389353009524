import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { ArticleState } from "./types";
import * as mutation from "./mutations";
import { Article } from "victoria-shared";
import api from "@/api";

function initialState(): ArticleState {
  return {
    articles: [],
    fetching: false
  };
}

const getters: GetterTree<ArticleState, RootState> = {
  hasArticles: state => state.articles && state.articles.length > 0
};

const actions: ActionTree<ArticleState, RootState> = {
  async initialize({ dispatch }): Promise<void> {
    await dispatch("fetchAll");
  },
  async fetchAll({ commit }): Promise<void> {
    try {
      commit(mutation.SET_FETCHING, true);
      const articles = await api.GetArticles();
      commit(mutation.SET_ARTICLES, articles);
    } finally {
      commit(mutation.SET_FETCHING, false);
    }
  },
  async fetchMore({ commit, state }): Promise<Article[]> {
    try {
      commit(mutation.SET_FETCHING, true);
      const offset = state.articles.length;
      const articles = await api.GetArticles(5, offset);
      commit(mutation.ADD_ARTICLES, articles);
      return articles;
    } finally {
      commit(mutation.SET_FETCHING, false);
    }
  },
  reset({ commit }) {
    commit(mutation.RESET);
  }
};

const mutations: MutationTree<ArticleState> = {
  [mutation.SET_ARTICLES](state, articles: Array<Article>) {
    state.articles = articles;
  },
  [mutation.ADD_ARTICLES](state, articles: Array<Article>) {
    const currentIds = new Set(state.articles.map(({ id }) => id));
    const newArticles = articles.filter(({ id }) => !currentIds.has(id));
    state.articles = [...state.articles, ...newArticles];
  },
  [mutation.SET_FETCHING](state, status: boolean) {
    state.fetching = status;
  },
  [mutation.RESET](state) {
    state.articles = [];
    state.fetching = false;
  }
};

const module: Module<ArticleState, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};

export default module;
