
import Vue, { ref } from "vue";
import { useStore } from "@/store";
export default Vue.extend({
  name: "DeleteAccountButton",
  setup() {
    const store = useStore();
    const dialog = ref(false);
    const loading = ref(false);
    const deleteAccount = async (): Promise<void> => {
      loading.value = true;
      store.dispatch("auth/deleteAccount");
      loading.value = false;
    };
    const close = (): void => {
      dialog.value = false;
    };
    return {
      loading,
      dialog,
      deleteAccount,
      close
    };
  }
});
