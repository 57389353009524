import { Chapter, ChapterMenuItem, JWT, TitleType } from "./types";

export function parseToken(token: string): JWT | undefined {
  try {
    return JSON.parse(atob(token.split(".")[1])) as JWT;
  } catch {
    return undefined;
  }
}

export function isGuid(guid: string) {
  const pattern = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
  return pattern.test(guid);
}

export function isEmail(email: string) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email?.trim());
}

const passwordRules = ["[a-z]", "[A-Z]", "[0-9]", "[^a-zA-Z0-9]"];

export function validatePassword(password: string) {
  return passwordRules.filter(rule => new RegExp(rule).test(password)).length;
}

export const VALIDATION = {
  email: () => ({ email: (value: string) => isEmail(value) || "Invalid e-mail." }),
  required: () => ({ required: (value: string) => !!value || "Required." }),
  min: (chars: number) => ({
    min: (value: string) => (value && value.length >= chars) || `Min ${chars} characters.`
  }),
  password: () => ({
    password: (value: string) => {
      const passes = validatePassword(value);
      return (
        passes >= 3 ||
        `Password must include at least three of the following: Uppercase letter, Lowercase letter, Numerical digit, Special character (${passes}/3).`
      );
    }
  })
};
const regExpThirdLevel = new RegExp(/<h3>(.*?)<\/h3>/g, "gi");
export function getThirdLevelItems(content: string, chapterId: string): Array<ChapterMenuItem> {
  const headings = content.matchAll(regExpThirdLevel) || [];
  return Array.from(headings).map(match => {
    const title = match[1];
    return { title, chapterId, type: TitleType.H3 };
  });
}

const regExpSecondLevel = new RegExp(/<h2>(.*?)((?=<h2>)|$)/g, "gi");
export function getSecondLevelItems(content: string, chapterId: string): Array<ChapterMenuItem> {
  const subContentMatches = content.matchAll(regExpSecondLevel) || [];
  return Array.from(subContentMatches)
    .filter(Boolean)
    .map(subContent => {
      const title = subContent[1].split("</h2>")[0];
      return {
        title,
        chapterId,
        children: getThirdLevelItems(subContent[1], chapterId),
        type: TitleType.H2
      };
    });
}

const sortChapters = (a: Chapter, b: Chapter) => a.displayOrder - b.displayOrder;

export function getChapterStructure(chapters: Chapter[]) {
  return chapters.sort(sortChapters).map(chapter => {
    const { chapterId, content, title } = chapter;
    let children: ChapterMenuItem[] = [];
    if (content) {
      children = getSecondLevelItems(content, chapterId);
    }
    return { title, chapterId, children };
  });
}
