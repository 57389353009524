
import Vue, { computed, ref } from "vue";
import { saveAs } from "file-saver";
import { User } from "victoria-shared";
import { AlertType } from "@/store/modules/alert/types";
import { useStore } from "@/store";
export default Vue.extend({
  name: "ExportPersonalDataButton",
  setup() {
    const store = useStore();
    const fileName = "nkt-victoria-handbook-personal-data.txt";
    const loading = ref(false);
    const user = computed((): User | undefined => {
      return store.state.auth.user;
    });
    const formatJson = (user: User): string => {
      return JSON.stringify(user, null, 4);
    };
    const save = async () => {
      loading.value = true;
      if (!user.value) return;
      try {
        const blob = new Blob([formatJson(user.value)], { type: "application/json" });
        saveAs(blob, fileName);
        store.dispatch("alert/add", {
          text: "Exported personal data.",
          type: AlertType.INFO
        });
      } catch {
        store.dispatch("alert/add", {
          text: "Failed exporting data.",
          type: AlertType.ERROR
        });
      }
      loading.value = false;
    };
    return {
      loading,
      save
    };
  }
});
