
import Vue, { computed, ref } from "vue";
import { mdiInformationOutline, mdiCardAccountMail, mdiPencil, mdiClose } from "@mdi/js";
import { User } from "victoria-shared";
import SignOutButton from "@/components/SignOutButton.vue";
import InstallGuideDialog from "@/components/InstallGuideDialog.vue";
import ExportPersonalDataButton from "@/components/ExportPersonalDataButton.vue";
import DeleteAccountButton from "@/components/DeleteAccountButton.vue";
import EditProfileForm from "@/components/EditProfileForm.vue";
import UserProfile from "@/components/UserProfile.vue";
import { useStore } from "@/store";
enum TAB {
  VIEW,
  EDIT
}
export default Vue.extend({
  name: "Profile",
  components: {
    SignOutButton,
    InstallGuideDialog,
    ExportPersonalDataButton,
    DeleteAccountButton,
    EditProfileForm,
    UserProfile
  },
  setup() {
    const store = useStore();
    const showInstallGuide = ref(false);
    const tab = ref(TAB.VIEW);
    const user = computed((): User | undefined => {
      return store.state.auth.user;
    });
    return {
      showInstallGuide,
      tab,
      user,
      TAB,
      mdiInformationOutline,
      mdiCardAccountMail,
      mdiPencil,
      mdiClose
    };
  }
});
