import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AuthState } from "./types";
import * as mutation from "./mutations";
import api from "@/api";
import { parseToken } from "@/utils";
import { LoginDetails } from "@/types";
import router from "@/router";
import { User } from "victoria-shared";
import { AlertType } from "../alert/types";
import { AxiosError } from "axios";

function initialState(): AuthState {
  return {
    user: undefined,
    token: ""
  };
}

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: state => !!(state.user && state.token),
  getTokenExpiry: state => {
    try {
      const exp = parseToken(state.token)?.exp;
      // Convert to milliseconds
      return exp ? exp * 1000 : undefined;
    } catch {
      return undefined;
    }
  }
};

const actions: ActionTree<AuthState, RootState> = {
  async login({ commit, dispatch }, loginDetails: LoginDetails) {
    const {
      firstName,
      lastName,
      company,
      email,
      phoneNumber,
      id,
      userType,
      jwtToken
    } = await api.login(loginDetails);
    commit(mutation.SET_USER, { firstName, lastName, company, email, phoneNumber, id, userType });
    commit(mutation.SET_TOKEN, jwtToken);
    dispatch("article/initialize", null, { root: true });
    dispatch("handbook/initialize", null, { root: true });
    dispatch("projects/initialize", null, { root: true });
    dispatch("information/initialize", null, { root: true });
  },
  async refreshToken({ commit }) {
    try {
      const result = await api.refreshToken();
      if (result) {
        commit(mutation.SET_TOKEN, result.jwtToken);
        return result.jwtToken;
      } else {
        // TODO: force logout?
      }
    } catch (error) {
      console.error("Refresh token error:", error);
    }
  },
  async logout({ dispatch }) {
    try {
      await api.logout();
    } finally {
      dispatch("clearState");
    }
  },
  async clearState({ commit, dispatch }) {
    commit(mutation.SET_USER, undefined);
    commit(mutation.SET_TOKEN, "");
    dispatch("handbook/reset", null, { root: true });
    dispatch("projects/reset", null, { root: true });
    dispatch("article/reset", null, { root: true });
    window.localStorage.clear();
    window.sessionStorage.clear();
    indexedDB.deleteDatabase("localforage");
    router.push({ name: "Login" });
  },
  async deleteAccount({ dispatch, state }) {
    try {
      if (!state.user) throw Error;
      await api.deleteAccount(state.user.id);
      dispatch(
        "alert/add",
        { text: "Account deleted successfully!", type: AlertType.SUCCESS },
        { root: true }
      );
      dispatch("clearState");
    } catch (error) {
      const errorResponse = error as AxiosError;
      const message = errorResponse?.response?.data?.message || "Failed deleting account";
      dispatch("alert/add", { text: message, type: AlertType.ERROR }, { root: true });
    }
  },
  async updateAccount({ commit, dispatch, state }, payload: Partial<User>): Promise<boolean> {
    try {
      if (!state.user) throw Error;
      const { email, phoneNumber } = await api.updateAccount(state.user.id, payload);
      commit(mutation.UPDATE_USER, { email, phoneNumber });
      dispatch(
        "alert/add",
        {
          text: "Updated profile successfully!",
          type: AlertType.SUCCESS,
          dismissible: true
        },
        { root: true }
      );
      return true;
    } catch (error) {
      const errorResponse = error as AxiosError;
      const message = errorResponse?.response?.data?.message || "Failed updating profile";
      dispatch("alert/add", { text: message, type: AlertType.ERROR }, { root: true });
      return false;
    }
  }
};

const mutations: MutationTree<AuthState> = {
  [mutation.SET_USER](state, user: User) {
    state.user = user;
  },
  [mutation.UPDATE_USER](state, user: User) {
    state.user = { ...state.user, ...user };
  },
  [mutation.SET_TOKEN](state, token: string) {
    state.token = token;
  }
};

const module: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};

export default module;
