
import Vue, { ref } from "vue";
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";
import LoginForm from "@/components/LoginForm.vue";
import Branding from "@/components/Branding.vue";

enum TAB {
  LOGIN,
  FORGOT
}

export default Vue.extend({
  name: "SignIn",
  components: { LoginForm, ForgotPasswordForm, Branding },
  setup() {
    const tabs = ref(TAB.LOGIN);
    return {
      tabs,
      TAB
    };
  }
});
