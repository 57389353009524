
import Vue, { computed } from "vue";
import HandbookMenu from "@/components/HandbookMenu.vue";
import { useStore } from "@/store";
export default Vue.extend({
  name: "Handbook",
  components: { HandbookMenu },
  setup() {
    const store = useStore();
    const refreshing = computed((): boolean => {
      return store.state.handbook.fetching as boolean;
    });
    return { refreshing };
  }
});
