import { Project } from "victoria-shared";
import request from "../request";

const CONTROLLER = "projects";

export default {
  async GetProjects(): Promise<Array<Project>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetProject(id: string): Promise<Project> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
