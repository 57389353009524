
import Vue, { ref } from "vue";
import { PHONE_DIRECTORY } from "@/phoneDirectory";
import PhoneDirectoryItem from "@/components/PhoneDirectoryItem.vue";

export default Vue.extend({
  name: "PhoneDirectory",
  components: { PhoneDirectoryItem },
  setup() {
    const directory = ref(PHONE_DIRECTORY);
    return { directory };
  }
});
