type PhoneDirectory = Array<PhoneCategory>;

interface PhoneCategory {
  title: string;
  numbers: Array<PhoneDirectoryNumber>;
  active?: boolean;
}

interface PhoneDirectoryNumber {
  number: string | number;
  description: string;
  external?: boolean;
}

export const PHONE_DIRECTORY: PhoneDirectory = [
  {
    title: "EXTERNAL NUMBERS",
    numbers: [
      {
        number: "+47 22408110",
        description: "Bridge",
        external: true
      },
      {
        number: "+47 22408120",
        description: "Captain",
        external: true
      },
      {
        number: "+47 22408103",
        description: "OCM",
        external: true
      },
      {
        number: "+47 22408124",
        description: "Chief Officer",
        external: true
      },
      {
        number: "+47 22408130",
        description: "Chief Engineer",
        external: true
      },
      {
        number: "+47 22408109",
        description: "Admin",
        external: true
      },
      {
        number: "+47 22408104",
        description: "Field Engineer",
        external: true
      }
    ]
  },
  {
    title: "TOP OF WHEELHOUSE",
    numbers: [
      {
        number: 909,
        description: "Heli Reception"
      },
      {
        number: 910,
        description: "Emergency Generator Room"
      }
    ]
  },
  {
    title: "BRIDGE DECK",
    numbers: [
      {
        number: 800,
        description: "Bridge Ringing Group"
      },
      {
        number: 801,
        description: "Bridge Console PS Fwd"
      },
      {
        number: 802,
        description: "Bridge Console SB Fwd"
      },
      {
        number: 803,
        description: "Bridge Console PS Aft"
      },
      {
        number: 804,
        description: "Bridge Console SB Aft"
      },
      {
        number: 805,
        description: "DP3 Em. Bridge (Bridge Wing PS)"
      },
      {
        number: 806,
        description: "Bridge Wing SB"
      },
      {
        number: 807,
        description: "Bridge Office"
      },
      {
        number: 808,
        description: "Cable Control"
      }
    ]
  },
  {
    title: "D - DECK",
    numbers: [
      {
        number: 701,
        description: "Chief Steward"
      },
      {
        number: 702,
        description: "1st Officer Cabin"
      },
      {
        number: 703,
        description: "Chief Eng. Cabin"
      },
      {
        number: 704,
        description: "Captain Cabin"
      },
      {
        number: 705,
        description: "1st Engineer Cabin"
      },
      {
        number: 706,
        description: "DPO"
      },
      {
        number: 708,
        description: "DPO"
      },
      {
        number: 709,
        description: "Instrument Room no.1"
      },
      {
        number: 710,
        description: "Chief Officer"
      },
      {
        number: 712,
        description: "Ships Office"
      },
      {
        number: 762,
        description: "Ships Office"
      },
      {
        number: 714,
        description: "Instrument Room no.2"
      }
    ]
  },
  {
    title: "C - DECK",
    numbers: [
      {
        number: 601,
        description: "2nd Engineer"
      },
      {
        number: 602,
        description: "Shift Supervisor 602"
      },
      {
        number: 603,
        description: "Field Engineer 603"
      },
      {
        number: 604,
        description: "Navofficer"
      },
      {
        number: 605,
        description: "2nd Engineer"
      },
      {
        number: 606,
        description: "Electrician"
      },
      {
        number: 607,
        description: "Client Cabin 607"
      },
      {
        number: 608,
        description: "Offshore Man. Cabin"
      },
      {
        number: 609,
        description: "Client Cabin 609"
      },
      {
        number: 610,
        description: "Owner 610"
      },
      {
        number: 611,
        description: "AB 1 Bosun 611"
      },
      {
        number: 612,
        description: "Shift Supervisor 612"
      },
      {
        number: 613,
        description: "Admin 613"
      },
      {
        number: 614,
        description: "Tech Shift Supervisor 614"
      },
      {
        number: 615,
        description: "Tech Shift Supervisor 615"
      },
      {
        number: 616,
        description: "AB 2 Ass CO 616"
      },
      {
        number: 617,
        description: "Cabin 617"
      },
      {
        number: 619,
        description: "Cook 619"
      },
      {
        number: 620,
        description: "Cabin 620"
      },
      {
        number: 630,
        description: "Offshore Manager 630"
      },
      {
        number: 632,
        description: "Client Office 632"
      },
      {
        number: 634,
        description: "Admin/HSE 634"
      },
      {
        number: 636,
        description: "OPS Room / Conference Room"
      },
      {
        number: 670,
        description: "Muster Station PS"
      },
      {
        number: 671,
        description: "Muster Station SB"
      },
      {
        number: 660,
        description: "Crane Cabin"
      }
    ]
  },
  {
    title: "B - DECK",
    numbers: [
      {
        number: 506,
        description: "AB 6 506"
      },
      {
        number: 507,
        description: "ROV Shift Supervisor 507"
      },
      {
        number: 508,
        description: "Cabin 508"
      },
      {
        number: 509,
        description: "AB 3 509"
      },
      {
        number: 510,
        description: "AB 4 510"
      },
      {
        number: 511,
        description: "ROV 511"
      },
      {
        number: 512,
        description: "Project Engineer 512"
      },
      {
        number: 513,
        description: "AB 5 513"
      },
      {
        number: 514,
        description: "Cabin 514"
      },
      {
        number: 515,
        description: "Cabin 515"
      },
      {
        number: 516,
        description: "Cabin 516"
      },
      {
        number: 517,
        description: "Cabin 517"
      },
      {
        number: 518,
        description: "Survey 518"
      },
      {
        number: 519,
        description: "Deck Foreman 519"
      },
      {
        number: 520,
        description: "Cabin 520"
      },
      {
        number: 521,
        description: "Deck Foreman 521"
      },
      {
        number: 522,
        description: "Offline Survey Room"
      },
      {
        number: 523,
        description: "ROV 523"
      },
      {
        number: 525,
        description: "Tech 525"
      },
      {
        number: 527,
        description: "Tech 527"
      },
      {
        number: 530,
        description: "ROV Control Room"
      },
      {
        number: 532,
        description: "ROV Control Room"
      },
      {
        number: 560,
        description: "Mooring Station Fwd"
      }
    ]
  },
  {
    title: "A - DECK",
    numbers: [
      {
        number: 404,
        description: "Cabin 404"
      },
      {
        number: 405,
        description: "Cabin 405"
      },
      {
        number: 406,
        description: "Medic 406"
      },
      {
        number: 407,
        description: "Cabin 407"
      },
      {
        number: 408,
        description: "Cabin 408"
      },
      {
        number: 410,
        description: "Cabin 410"
      },
      {
        number: 412,
        description: "Cabin 412"
      },
      {
        number: 413,
        description: "Cabin 413"
      },
      {
        number: 414,
        description: "Cabin 414"
      },
      {
        number: 415,
        description: "Cabin 415"
      },
      {
        number: 416,
        description: "Cabin 416"
      },
      {
        number: 417,
        description: "Cabin 417"
      },
      {
        number: 418,
        description: "Cabin 418"
      },
      {
        number: 419,
        description: "Cabin 419"
      },
      {
        number: 420,
        description: "Cabin 420"
      },
      {
        number: 421,
        description: "Cabin 421"
      },
      {
        number: 423,
        description: "Cabin 423"
      },
      {
        number: 424,
        description: "Cabin 424"
      },
      {
        number: 425,
        description: "Cabin 425"
      },
      {
        number: 426,
        description: "Cabin 426"
      },
      {
        number: 428,
        description: "Cabin 428"
      },
      {
        number: 429,
        description: "Cabin 429"
      },
      {
        number: 430,
        description: "Cabin 430"
      },
      {
        number: 432,
        description: "Cabin 432"
      },
      {
        number: 433,
        description: "ISPS Reception"
      },
      {
        number: 434,
        description: "Cabin 434"
      },
      {
        number: 436,
        description: "Cabin 436"
      },
      {
        number: 438,
        description: "Cabin 438"
      },
      {
        number: 440,
        description: "Cabin 440"
      },
      {
        number: 442,
        description: "Cabin 442"
      },
      {
        number: 444,
        description: "Cabin 444"
      },
      {
        number: 446,
        description: "ROV El. & Mech. Workshop"
      },
      {
        number: 448,
        description: "Cabin 448"
      },
      {
        number: 460,
        description: "Tensioner Control Cabin"
      }
    ]
  },
  {
    title: "01 - DECK",
    numbers: [
      {
        number: 307,
        description: "Mess Room"
      },
      {
        number: 309,
        description: "Deck Foreman/Field Engineers"
      },
      {
        number: 359,
        description: "Deck Foreman/Field Engineers"
      },
      {
        number: 312,
        description: "Day Room 1"
      },
      {
        number: 316,
        description: "Gymnasium"
      },
      {
        number: 317,
        description: "Chief Steward Office"
      },
      {
        number: 318,
        description: "Hospital"
      },
      {
        number: 321,
        description: "Galley"
      },
      {
        number: 322,
        description: "Tech Office"
      },
      {
        number: 324,
        description: "Project Engineers"
      },
      {
        number: 326,
        description: "Female Change Room"
      },
      {
        number: 330,
        description: "Conference Room"
      },
      {
        number: 332,
        description: "Male Change Room"
      },
      {
        number: 334,
        description: "Reception"
      },
      {
        number: 338,
        description: "Duty Mess"
      },
      {
        number: 339,
        description: "Shore Connection"
      },
      {
        number: 354,
        description: "Deck Workshop"
      },
      {
        number: 360,
        description: "Utility Station in Deck House"
      },
      {
        number: 355,
        description: "Cable Tool Workshop"
      },
      {
        number: 333,
        description: "Medic All Ringing Group"
      },
      {
        number: 318,
        description: "Hospital"
      },
      {
        number: 406,
        description: "Medic 406"
      },
      {
        number: 959,
        description: "Medic"
      }
    ]
  },
  {
    title: "02 - DECK",
    numbers: [
      {
        number: 201,
        description: "Thruster Room no.1"
      },
      {
        number: 202,
        description: "Thruster Room no.2"
      },
      {
        number: 200,
        description: "ECR Ringing Group"
      },
      {
        number: 210,
        description: "ECR Console"
      },
      {
        number: 250,
        description: "ECR Desk"
      },
      {
        number: 211,
        description: "Switchboard Room no.2"
      },
      {
        number: 212,
        description: "El. Workshop/Store"
      },
      {
        number: 213,
        description: "Switchboard Room no.1"
      },
      {
        number: 226,
        description: "Propulsion Room no.2"
      },
      {
        number: 228,
        description: "Propulsion Room no.1"
      },
      {
        number: 260,
        description: "Mooring Station Aft PS"
      },
      {
        number: 261,
        description: "Mooring Station Aft SB"
      }
    ]
  },
  {
    title: "TANK TOP",
    numbers: [
      {
        number: 101,
        description: "Generator Room no.1"
      },
      {
        number: 102,
        description: "Generator Room no.2"
      },
      {
        number: 103,
        description: "Engine Workshop"
      },
      {
        number: 110,
        description: "Propulsion Room no.2 Center"
      }
    ]
  },
  {
    title: "WIRELESS PHONES",
    numbers: [
      {
        number: 950,
        description: "Bridge DECT"
      },
      {
        number: 951,
        description: "ECR DECT"
      },
      {
        number: 952,
        description: "Chief Engineer"
      },
      {
        number: 953,
        description: "Chief Steward"
      },
      {
        number: 954,
        description: "ROV Superintendant"
      },
      {
        number: 955,
        description: "Captain"
      },
      {
        number: 956,
        description: "Offshore Manager"
      },
      {
        number: 957,
        description: "Client"
      },
      {
        number: 958,
        description: "Electrician"
      },
      {
        number: 959,
        description: "Medic"
      },
      {
        number: 960,
        description: "Shift supervisor"
      },
      {
        number: 961,
        description: "Chief Officer"
      },
      {
        number: 962,
        description: "Field Engineer"
      },
      {
        number: 963,
        description: "Lay Tech"
      },
      {
        number: 964,
        description: "MWS"
      },
      {
        number: 965,
        description: "Admin/Storekeeper"
      },
      {
        number: 966,
        description: "Deck Foreman"
      },
      {
        number: 967,
        description: "Project Engineers"
      },
      {
        number: 970,
        description: "Bosun"
      }
    ]
  }
];
