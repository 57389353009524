
import Vue, { ref } from "vue";
import { TERMS } from "@/terms";

export default Vue.extend({
  name: "TermsOfService",
  setup() {
    const dialog = ref(false);
    const close = (): void => {
      dialog.value = false;
    };
    return {
      TERMS,
      dialog,
      close
    };
  }
});
