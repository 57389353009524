
import Vue from "vue";
import { mdiBookOpenVariant, mdiPhoneLog } from "@mdi/js";
import Shortcut from "./Shortcut.vue";

export default Vue.extend({
  components: { Shortcut },
  name: "Shortcuts",
  setup() {
    return {
      mdiBookOpenVariant,
      mdiPhoneLog
    };
  }
});
