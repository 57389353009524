
import Vue, { getCurrentInstance } from "vue";
import { ref, computed } from "vue";
import ArticleList from "@/components/ArticleList.vue";
import { Article } from "victoria-shared";
import { AlertType } from "@/store/modules/alert/types";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "ArticleSection",
  components: { ArticleList },
  setup() {
    const proxy = getCurrentInstance()?.proxy;
    const stateRestored = ref(false);
    const isStateRestored = async () => {
      // @ts-expect-error Wait for handbook state to be restored,
      stateRestored.value = !!((await proxy?.$store.restored) || true);
    };
    const articles = computed(() => {
      return (proxy?.$store.state.article.articles || []) as Article[];
    });
    const refreshing = computed(() => {
      return proxy?.$store.state.article.fetching as boolean;
    });
    const fetchArticles = async (): Promise<void> => {
      try {
        await proxy?.$store.dispatch("article/fetchAll");
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Could not fetch news articles.";
        proxy?.$store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
      }
    };
    isStateRestored();
    return { stateRestored, articles, refreshing, fetchArticles };
  }
});
