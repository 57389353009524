import { RootState } from "@/store/types";
import { ActionTree, Module, MutationTree } from "vuex";
import * as mutation from "./mutations";
import { SettingsState } from "./types";

const isAppStandalone = () => window.matchMedia("(display-mode: standalone)").matches;

function initialState(): SettingsState {
  return {
    installAlert: isAppStandalone() ? false : true
  };
}

const actions: ActionTree<SettingsState, RootState> = {
  setInstallAlert({ commit }, state: boolean) {
    commit(mutation.SET_INSTALL_ALERT, state);
  },
  reset({ commit }) {
    commit(mutation.RESET);
  }
};

const mutations: MutationTree<SettingsState> = {
  [mutation.SET_INSTALL_ALERT](state, status: boolean) {
    state.installAlert = status;
  },
  [mutation.RESET](state) {
    state.installAlert = isAppStandalone() ? false : true;
  }
};

const module: Module<SettingsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
};

export default module;
