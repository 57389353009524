
import Vue, { Ref, ref } from "vue";
import { mdiClose } from "@mdi/js";
import api from "@/api";
import { VuetifyForm } from "@/types";
import { AlertType } from "@/store/modules/alert/types";
import { VALIDATION } from "@/utils";
import { AxiosError } from "axios";
import { useStore } from "@/store";
export default Vue.extend({
  name: "ForgotPasswordForm",
  setup(_, context) {
    const store = useStore();
    const loading = ref(false);
    const valid = ref(false);
    const email: Ref<string | undefined> = ref(undefined);
    const rules = {
      ...VALIDATION.required(),
      ...VALIDATION.email()
    };
    const form: Ref<VuetifyForm | undefined> = ref(undefined);
    const getForm = (): VuetifyForm | undefined => {
      return form.value;
    };
    const validate = (): boolean | undefined => {
      return getForm()?.validate();
    };
    const submit = async (): Promise<void> => {
      loading.value = true;
      if (validate()) {
        try {
          if (email.value) {
            const { message } = await api.forgotPassword(email?.value);
            store.dispatch("alert/addPersistent", {
              text: message,
              type: AlertType.INFO,
              dismissible: true
            });
          }
          context.emit("cancel");
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message = errorResponse?.response?.data?.message || "Failed resetting password";
          store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
        }
      }
      loading.value = false;
    };
    return {
      loading,
      valid,
      rules,
      email,
      form,
      submit,
      mdiClose
    };
  }
});
