
import Vue from "vue";
import { computed, PropType } from "vue";
import { Article } from "victoria-shared";
import { mdiArrowRight } from "@mdi/js";
import { truncate } from "lodash";

const SUMMARY_CHAR_LIMIT = 200;

export default Vue.extend({
  name: "ArticleCard",
  props: {
    article: {
      type: Object as PropType<Article>,
      default: undefined
    }
  },
  setup(props) {
    const theArticle = computed(() => {
      return props.article;
    });
    const stripAllTags = (content: string): string => {
      return content.replace(/<(.|\n)*?>/g, " ");
    };
    const summary = computed(() => {
      const content = stripAllTags(theArticle.value.content || "");
      return truncate(content, {
        length: SUMMARY_CHAR_LIMIT
      });
    });
    const date = computed(() => {
      const date = new Date(`${theArticle.value.created}Z`);
      return date.toLocaleString();
    });
    return {
      theArticle,
      summary,
      date,
      mdiArrowRight
    };
  }
});
