
import Vue, { ref, toRefs } from "vue";
enum TABS {
  ANDROID = 0,
  IOS = 1
}

export default Vue.extend({
  name: "InstallGuideDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { visible } = toRefs(props);
    const tab = ref(TABS.ANDROID);
    const isIos = (): boolean => {
      if (!navigator || !navigator.platform || !navigator.userAgent) return false;
      return (
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
          navigator.platform
        ) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    };
    tab.value = isIos() ? TABS.IOS : TABS.ANDROID;
    const close = (): void => {
      context.emit("update:visible", false);
    };
    return {
      visible,
      tab,
      close
    };
  }
});
