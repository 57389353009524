import { Article } from "victoria-shared";
import request from "../request";

const CONTROLLER = "articles";

const DEFAULT_LIMIT = 5;

export default {
  async GetArticles(limit = DEFAULT_LIMIT, offset = 0): Promise<Array<Article>> {
    const req = await request.get(`${CONTROLLER}`, {
      params: { limit, offset }
    });
    return req.status === 200 ? req.data : [];
  },
  async GetArticle(id: string): Promise<Article> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
