
import Shortcuts from "@/components/Shortcuts.vue";
import InstallAlert from "@/components/InstallAlert.vue";
import ArticleSection from "@/components/ArticleSection.vue";
import Vue from "vue";

export default Vue.extend({
  components: { Shortcuts, InstallAlert, ArticleSection },
  name: "Home"
});
