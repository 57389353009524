
import Vue, { toRefs } from "vue";
import { mdiPhone } from "@mdi/js";

export default Vue.extend({
  name: "PhoneDirectoryItem",
  props: {
    number: [String, Number],
    external: {
      type: Boolean,
      default: false
    },
    description: String
  },
  setup(props) {
    const { number, external, description } = toRefs(props);
    return { number, external, description, mdiPhone };
  }
});
