
import Vue, { computed, ref } from "vue";
import ProjectList from "@/components/ProjectList.vue";
import { Project } from "victoria-shared";
import { AlertType } from "@/store/modules/alert/types";
import { AxiosError } from "axios";
import { useStore } from "@/store";

export default Vue.extend({
  name: "Projects",
  components: { ProjectList },
  setup() {
    const store = useStore();
    const stateRestored = ref(false);
    const isStateRestored = (): void => {
      stateRestored.value = !!store?.state.projects.projects || true;
    };
    isStateRestored();
    const projects = computed((): Project[] => {
      return store.state.projects.projects || [];
    });
    const refreshing = computed((): boolean => {
      return store.state.projects.fetching;
    });
    const fetchProjects = async (): Promise<void> => {
      try {
        await store.dispatch("projects/fetchAll");
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Could not fetch projects.";
        store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
      }
    };
    return {
      stateRestored,
      projects,
      refreshing,
      fetchProjects
    };
  }
});
