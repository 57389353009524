
import Vue, { PropType, computed, toRefs } from "vue";
import { mdiClose, mdiDomain, mdiPhone, mdiEmail, mdiCardAccountMail } from "@mdi/js";
import { User } from "victoria-shared";

export default Vue.extend({
  name: "UserProfile",
  setup(props) {
    const { user } = toRefs(props);
    const theUser = computed(
      (): User => {
        return user.value;
      }
    );
    return {
      mdiClose,
      mdiDomain,
      mdiPhone,
      mdiEmail,
      mdiCardAccountMail,
      theUser,
      user
    };
  },
  props: {
    user: {} as PropType<User>
  }
});
