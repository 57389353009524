export interface Chapter {
  chapterId: string;
  displayOrder: number;
  title: string;
  content?: string;
  parent?: Chapter;
  parentId?: string;
  subChapters?: Array<Chapter>;
  created: string;
  updated: string;
  deleted?: string;
}

export enum TitleType {
  H2 = "h2",
  H3 = "h3"
}
export interface ChapterMenuItem {
  title: string;
  chapterId: string;
  children?: ChapterMenuItem[];
  type?: TitleType;
}

export interface JWT {
  exp: number;
}

export interface LoginDetails {
  email: string;
  password: string;
}

export interface RegisterRequest {
  email: string;
  invite: string;
  firstName: string;
  lastName: string;
  company: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

export interface ResetRequest {
  token: string | undefined;
  password: string;
  confirmPassword: string;
}

export interface VuetifyForm {
  value: boolean;
  disabled: boolean;
  reset(): void;
  validate(): boolean;
  resetValidation(): void;
}
