
import Vue, { PropType, computed, toRefs } from "vue";
import { Article } from "victoria-shared";
import ArticleCard from "@/components/ArticleCard.vue";
import LoadMoreArticlesButton from "@/components/LoadMoreArticlesButton.vue";

export default Vue.extend({
  name: "ArticleList",
  components: { ArticleCard, LoadMoreArticlesButton },
  setup(props) {
    const { articles } = toRefs(props);
    const allArticles = computed(() => {
      return articles.value;
    });
    return {
      allArticles
    };
  },
  props: {
    articles: {
      type: Array as PropType<Article[]>,
      default: () => []
    }
  }
});
