
import Vue, { PropType, computed, toRefs } from "vue";
import { Project } from "victoria-shared";

enum ProjectStatus {
  FUTURE = "Future",
  ACTIVE = "Active",
  PAST = "Past"
}

export default Vue.extend({
  name: "ProjectStatusChip",
  props: {
    project: Object as PropType<Project>,
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { project, small } = toRefs(props);
    const status = computed((): string => {
      const currentDate = new Date();
      const completion = new Date(project.value.completion);
      const commencement = new Date(project.value.commencement);
      if (completion < currentDate) {
        return ProjectStatus.PAST;
      }
      if (commencement > currentDate) {
        return ProjectStatus.FUTURE;
      }
      return ProjectStatus.ACTIVE;
    });
    const color = computed((): string => {
      switch (status.value) {
        case ProjectStatus.ACTIVE:
          return "teal lighten-4";
        case ProjectStatus.PAST:
          return "red lighten-4";
        case ProjectStatus.FUTURE:
          return "blue lighten-4";
        default:
          return "dark";
      }
    });
    return {
      color,
      small,
      status
    };
  }
});
