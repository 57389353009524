import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { ProjectState } from "./types";
import * as mutation from "./mutations";
import { Project } from "victoria-shared";
import api from "@/api";
import { orderBy } from "lodash";

function initialState(): ProjectState {
  return {
    projects: [],
    fetching: false
  };
}

const getters: GetterTree<ProjectState, RootState> = {
  hasProjects: state => state.projects && state.projects.length > 0
};

const actions: ActionTree<ProjectState, RootState> = {
  async initialize({ dispatch }): Promise<void> {
    await dispatch("fetchAll");
  },
  async fetchAll({ commit }): Promise<void> {
    try {
      commit(mutation.SET_FETCHING, true);
      const projects = await api.GetProjects();
      const sortedProjects = orderBy(projects, "commencement", "desc");
      commit(mutation.SET_PROJECTS, sortedProjects);
    } finally {
      commit(mutation.SET_FETCHING, false);
    }
  },
  reset({ commit }) {
    commit(mutation.RESET);
  }
};

const mutations: MutationTree<ProjectState> = {
  [mutation.SET_PROJECTS](state, projects: Array<Project>) {
    state.projects = projects;
  },
  [mutation.SET_FETCHING](state, status: boolean) {
    state.fetching = status;
  },
  [mutation.RESET](state) {
    state.projects = [];
    state.fetching = false;
  }
};

const module: Module<ProjectState, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};

export default module;
