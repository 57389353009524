import store from "@/store";
import { AlertType } from "@/store/modules/alert/types";
import { isGuid } from "@/utils";
import { NavigationGuardNext, Route } from "vue-router";
import { UNGUARDED_ROUTES } from "./routes";

export function authGuard(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const isAuthorized = isAuthenticated || (to.name && UNGUARDED_ROUTES.includes(to.name));
  isAuthorized ? next() : next({ name: "Login" });
}

export function registerGuard(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
  authGuard(to, _from, next);
  const { token } = to.params;
  if (!isGuid(token)) {
    next({ name: "Login" });
    store.dispatch("alert/add", {
      text: "Invalid invitation link.",
      type: AlertType.WARNING
    });
    next();
  }
}

export function loginGuard(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  isAuthenticated ? next({ name: "Home" }) : next();
}
