
import Vue, { getCurrentInstance, computed, onBeforeMount } from "vue";
import { mdiInformation } from "@mdi/js";
import { useStore } from "@/store";
import { Article } from "victoria-shared";
export default Vue.extend({
  name: "InformationArticle",
  setup() {
    const store = useStore();
    const proxy = getCurrentInstance()?.proxy;
    const articleId = computed((): string | undefined => {
      return proxy?.$route.params.id;
    });
    const article = computed((): Article | undefined => {
      return store.state.information.articles.find(({ id }) => id === articleId.value);
    });
    const created = computed((): string => {
      const date = new Date(`${article?.value?.created}Z`);
      return date.toLocaleString();
    });
    const updated = computed((): string => {
      const date = new Date(`${article?.value?.updated}Z`);
      return date.toLocaleString();
    });
    const isUpdated = computed((): boolean => {
      return article?.value?.created !== article?.value?.updated;
    });

    // router.beforeEach(async(to,from,next)=>{
    //   // @ts-expect-error Wait for handbook state to be restored,
    //   await store.restored;
    //   if (!article.value) {
    //     proxy?.$router.replace({ name: "404" });
    //   }
    //   next();
    // });

    onBeforeMount(async () => {
      // @ts-expect-error Wait for handbook state to be restored,
      await store.restored;
      console.log(article.value, "article value");
      if (!article.value) {
        proxy?.$router.replace({ name: "404" });
      }
    });

    return {
      articleId,
      article,
      created,
      updated,
      isUpdated,
      mdiInformation
    };
  }
});
