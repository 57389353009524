
import Vue, { ref } from "vue";
import { useStore } from "@/store";
export default Vue.extend({
  name: "SignOutButton",
  setup() {
    const store = useStore();
    const loading = ref(false);
    const signOut = async (): Promise<void> => {
      loading.value = true;
      await store.dispatch("auth/logout");
      store.dispatch("alert/add", { text: "Logged out successfully!" });
      loading.value = false;
    };
    return {
      loading,
      signOut
    };
  }
});
