<template>
  <v-snackbar v-model="visible" bottom :timeout="-1">
    <span>A new version of the app is available.</span>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click.stop="refreshApp">
        Refresh
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref } from "vue";

export default {
  name: "AppUpdateAlert",
  setup() {
    const refreshing = ref(false);
    const registration = ref(undefined);
    const visible = ref(false);

    // Function to show the refresh UI
    const showRefreshUI = e => {
      registration.value = e.detail;
      visible.value = true;
    };

    // Function to refresh the application
    const refreshApp = () => {
      visible.value = false;
      if (!registration.value || !registration.value.waiting) {
        return;
      }
      registration.value.waiting.postMessage("skipWaiting");
    };

    // Listen for swUpdated event
    document.addEventListener("swUpdated", showRefreshUI, { once: true });

    // Refresh all open app tabs when a new service worker is installed
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (refreshing.value) return;
        refreshing.value = true;
        window.location.reload();
      });
    }

    return { refreshing, registration, visible, refreshApp };
  }
};
</script>
