import { Invite } from "victoria-shared";
import request from "../request";

const CONTROLLER = "invites";

export default {
  async getInvite(id: string): Promise<Invite> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
