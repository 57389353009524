import { Chapter } from "@/types";
import request from "../request";

export default {
  async GetChapters(): Promise<Array<Chapter>> {
    const req = await request.get("chapters");
    return req.status === 200 ? req.data : [];
  },
  async GetChaptersCompact(): Promise<Array<Chapter>> {
    const req = await request.get("chapters", {
      params: { compact: true }
    });
    return req.status === 200 ? req.data : [];
  },
  async GetChapter(id: string): Promise<Chapter> {
    const req = await request.get(`chapters/${id}`);
    return req.data;
  }
};
