import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { differenceBy } from "lodash";
import { HandbookState } from "./types";
import * as mutation from "./mutations";
import { Chapter } from "@/types";
import api from "@/api";
import { getChapterStructure } from "@/utils";

function initialState(): HandbookState {
  return {
    chapters: [],
    fetching: false
  };
}

const getters: GetterTree<HandbookState, RootState> = {
  menu: state => getChapterStructure(state.chapters),
  hasChapters: state => state.chapters && state.chapters.length > 0
};

const actions: ActionTree<HandbookState, RootState> = {
  async initialize({ dispatch, getters }): Promise<void> {
    if (!getters.hasChapters) {
      await dispatch("fetchChapters");
    } else {
      await dispatch("updateChapters");
    }
  },
  async fetchChapters({ commit }): Promise<void> {
    try {
      commit(mutation.SET_FETCHING, true);
      const chapters = await api.GetChapters();
      commit(mutation.SET_CHAPTERS, chapters);
    } finally {
      commit(mutation.SET_FETCHING, false);
    }
  },
  async updateChapters({ commit, state }) {
    commit(mutation.SET_FETCHING, true);
    const { chapters } = state;
    const latestChapters = await api.GetChaptersCompact();
    const updatedChapters: string[] = [];
    const newChapters: string[] = [];
    const removedChapters = differenceBy(chapters, latestChapters, "chapterId");
    removedChapters.length &&
      commit(
        mutation.REMOVE_CHAPTERS,
        removedChapters.map(({ chapterId }) => chapterId)
      );
    latestChapters.forEach(newChapter => {
      const chapter = chapters.find(({ chapterId }) => chapterId === newChapter.chapterId);
      if (!chapter) {
        newChapters.push(newChapter.chapterId);
      } else if (newChapter.updated !== chapter.updated) {
        updatedChapters.push(newChapter.chapterId);
      } else if (newChapter.displayOrder !== chapter.displayOrder) {
        commit(mutation.SET_CHAPTER, { ...chapter, ...newChapter });
      }
    });
    const promises: Array<Promise<void>> = [];
    [...updatedChapters, ...newChapters].forEach(id => {
      const promise = async () => {
        try {
          console.info("Updating chapter cache for:", id);
          const chapter = await api.GetChapter(id);
          commit(mutation.SET_CHAPTER, chapter);
        } catch {
          console.warn("Failed getting chapter:", id);
        }
      };
      promises.push(promise());
    });
    Promise.all(promises);
    commit(mutation.SET_FETCHING, false);
  },
  reset({ commit }) {
    commit(mutation.RESET);
  }
};

const mutations: MutationTree<HandbookState> = {
  [mutation.SET_CHAPTERS](state, chapters: Array<Chapter>) {
    state.chapters = chapters;
  },
  [mutation.SET_CHAPTER](state, chapter: Chapter) {
    const index = state.chapters.findIndex(entry => entry.chapterId === chapter.chapterId);
    if (index !== -1) {
      const chapters = state.chapters.slice(0);
      chapters[index] = chapter;
      state.chapters = chapters;
    } else {
      state.chapters = [...state.chapters, chapter];
    }
  },
  [mutation.REMOVE_CHAPTERS](state, ids: string[]) {
    state.chapters = state.chapters.filter(({ chapterId }) => !ids.includes(chapterId));
  },
  [mutation.SET_FETCHING](state, status: boolean) {
    state.fetching = status;
  },
  [mutation.RESET](state) {
    state.chapters = [];
    state.fetching = false;
  }
};

const module: Module<HandbookState, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};

export default module;
