
import Vue, { PropType, computed, ref, toRefs } from "vue";
import { Project } from "victoria-shared";
import ProjectStatusChip from "@/components/ProjectStatusChip.vue";
import { orderBy } from "lodash";

export default Vue.extend({
  name: "ProjectList",
  components: { ProjectStatusChip },
  props: {
    projects: {
      type: Array as PropType<Project[]>,
      default: () => []
    }
  },
  setup(props) {
    const { projects } = toRefs(props);
    const summaryPlaceholder = ref("No project summary available." as string);
    const oneYearAgo = (): Date => {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    };
    const allProjects = computed((): Project[] => {
      const currentDate = new Date();
      const sortedProjects = orderBy(projects.value, "commencement", "asc");
      const orderedProjects = [
        ...sortedProjects.filter(({ completion }) => new Date(completion) >= currentDate),
        ...sortedProjects.filter(({ completion }) => new Date(completion) < currentDate)
      ];
      const filteredProjects = orderedProjects.filter(
        project => new Date(project.completion) > oneYearAgo()
      );
      return filteredProjects;
    });
    return {
      summaryPlaceholder,
      allProjects
    };
  }
});
