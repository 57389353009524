
import Vue, { PropType, computed, toRefs } from "vue";
import { Article } from "victoria-shared";
import { mdiArrowRight, mdiInformation } from "@mdi/js";
import { truncate } from "lodash";

const SUMMARY_CHAR_LIMIT = 200;

export default Vue.extend({
  name: "InformationArticleCard",
  props: {
    article: {
      type: Object as PropType<Article>,
      default: undefined
    }
  },
  setup(props) {
    const { article } = toRefs(props);
    const stripAllTags = (content: string): string => {
      return content.replace(/<(.|\n)*?>/g, " ");
    };
    const theArticle = computed(
      (): Article => {
        return article.value;
      }
    );
    const summary = computed((): string => {
      const content = stripAllTags(theArticle.value.content);
      return truncate(content, {
        length: SUMMARY_CHAR_LIMIT
      });
    });
    const date = computed((): string => {
      const date = new Date(`${theArticle.value.created}Z`);
      return date.toLocaleString();
    });
    return {
      mdiArrowRight,
      mdiInformation,
      theArticle,
      summary,
      date
    };
  }
});
