
import Vue, { Ref, computed, getCurrentInstance, ref } from "vue";

import { mdiAccount, mdiEye, mdiEyeOff } from "@mdi/js";
import { VuetifyForm } from "@/types";
import api from "@/api";
import { AlertType } from "@/store/modules/alert/types";
import { VALIDATION } from "@/utils";
import TermsOfService from "@/components/TermsOfService.vue";
import Branding from "@/components/Branding.vue";
import { AxiosError } from "axios";
import { useStore } from "@/store";

export default Vue.extend({
  name: "Register",
  components: { TermsOfService, Branding },
  setup() {
    const instance = getCurrentInstance();
    const router = instance?.proxy;
    const store = useStore();
    const loading = ref(true);
    const valid = ref(false);
    const inviteId: Ref<string | undefined> = ref(undefined);
    const form = ref({
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      termsAccepted: false
    });
    const showPassword = ref(false);
    const rules = {
      ...VALIDATION.required(),
      ...VALIDATION.min(8),
      ...VALIDATION.password()
    };
    const formInput: Ref<VuetifyForm | undefined> = ref(undefined);
    const passwordMatch = computed((): (() => boolean | string) => {
      return () => form.value.password === form.value.confirmPassword || "Password must match.";
    });
    const getForm = (): VuetifyForm | undefined => {
      return formInput.value;
    };
    const validate = (): boolean | undefined => {
      return getForm()?.validate();
    };
    const submit = async (): Promise<void> => {
      if (loading.value) {
        return;
      }
      loading.value = true;
      if (validate()) {
        try {
          inviteId.value && (await api.register({ invite: inviteId.value, ...form.value }));
          router?.$router.replace({ name: "RegisterSuccess" });
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message = errorResponse?.response?.data?.message || "Registration failed";
          store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
        }
      }
      loading.value = false;
    };
    const getInvite = async (): Promise<void> => {
      inviteId.value = router?.$route.params.token;
      try {
        if (inviteId.value) {
          const invite = await api.getInvite(inviteId.value);
          form.value.email = invite.email;
        }
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Registration failed";
        store.dispatch("alert/add", { text: message, type: AlertType.ERROR });
        router?.$router.push({ name: "Login" });
      }
      loading.value = false;
    };
    getInvite();
    return {
      loading,
      valid,
      inviteId,
      form,
      showPassword,
      rules,
      mdiAccount,
      mdiEye,
      mdiEyeOff,
      formInput,
      passwordMatch,
      getForm,
      validate,
      submit
    };
  }
});
